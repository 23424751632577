<template>
  <div class="auth">
    <div class="auth__loader" v-if="isLoading">
      <video-loader></video-loader>
    </div>
    <div class="auth__form">
      <p class="auth__title">Панель Менеджера</p>
      <alert-text
          v-if="isWrongData"
          class="auth__alert"
          value="Ошибка!<br/> Неправильный логин или пароль"/>
      <InputAuth
          class="auth__input"
          label="ЛОГИН"
          icon-class="user"
          type="input"
          v-model="login"
          id="login"/>
      <InputAuth
          class="auth__input"
          label="ПАРОЛЬ"
          icon-class="show"
          type="password"
          v-model="password"
          id="password"/>
      <ButtonPink
          class="auth__button"
          value="ВОЙТИ"
          size="md"
          @click.native="auth"
          :disabled="isWrongData"/>
    </div>
  </div>
</template>

<script>
import VideoLoader from "@/components/Auth/VideoLoader";
import AlertText from "@/components/Auth/AlertText";
import InputAuth from "@/components/Auth/InputAuth";
import ButtonPink from "@/components/Auth/ButtonPink";

export default {
  name: "AuthView",
  components: {ButtonPink, InputAuth, AlertText, VideoLoader},
  data(){
    return {
      isWrongData: false,
      isLoading: false,
      login : "",
      password : ""
    }
  },
  computed: {
    isMobile () {
      return screen.width < 992
    }
  },
  watch:{
    login(){
      this.isWrongData = false
    },
    password(){
      this.isWrongData = false
    }
  },
  methods:{
    async auth () {
      this.isLoading = true
      this.isWrongData = false
      setTimeout(async () => {
        const res = await this.$axios.post('manager/auth', {
          userName: this.login,
          password: this.password
        })
        if (res.status === 200){
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')

          localStorage.setItem('accessToken', res.data.accessToken)
          localStorage.setItem('refreshToken', res.data.refreshToken)

          await this.$router.push({ name: 'Home' })

        } else {
          this.isLoading = false
          this.isWrongData = true
        }
      }, 1000)
    },
  },
  mounted(){
  }
}
</script>

<style scoped lang="scss">
.auth{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form{
    background: #FFFFFF;
    border: 1px solid rgba(198,206,212,0.50);
    border-radius: 4px;
    width: 350px;
    height: auto;
    min-height: 350px;
    padding-left: 30px;
  }

  &__title{
    margin-top: 28px;
    font-family: CodeNext;
    font-weight: 800;
    letter-spacing: 0;
    font-size: 24px;
    color: #222222;
  }

  &__alert{
    margin-top: 10px;
    margin-bottom: -10px;

  }

  &__input{
    margin-top: 30px;
  }

  &__button{
    margin-top: 47px;
    margin-bottom: 45px;
  }

  &__loader{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.16);
    position: absolute;
    z-index: 1;

    div{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;
    }
  }


}
</style>